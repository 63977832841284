export var LabelsData = [
    {
        name: '台州花木城景区',
        position: [121.400754,28.526864],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 10,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '台州花木城景区',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '台州市路桥无问西东民宿',
        position: [121.379426,28.579584],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 16,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '台州市路桥无问西东民宿',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '中共黄岩首届县委旧址',
        position: [121.127123,28.57734],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 8,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '中共黄岩首届县委旧址',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '陈安宝纪念馆',
        position: [121.444201,28.535745],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 23,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '陈安宝纪念馆',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '解放一江山岛军事遗址',
        position: [121.619075,28.539004],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 6,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '解放一江山岛军事遗址',
            direction: 'left',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
    {
        name: '筑塘博物馆',
        position: [121.485836,28.552283],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 5,
        icon: {
            type: 'image',
            image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [25, 34],
            anchor: 'bottom-center',
            angel: 0,
            retina: true
        },
        text: {
            content: '筑塘博物馆',
            direction: 'right',
            offset: [0, -5],
            style: {
                fontSize: 13,
                fontWeight: 'normal',
                fillColor: '#fff',
                padding: '2, 5',
                backgroundColor: '#1492FF'
            }
        }
    },
];