<template>
  <div class="body">
    <div class="head">
      <div class="left">游有所乐</div>
      <div class="right">牵头单位：路桥区文广旅体局</div>
    </div>
    <div class="btn">
      <div
        :class="item.index == indexA ? 'active' : ''"
        v-for="(item, index) in btnItem"
        :key="index"
        :style="item.index == 2 || item.index == 3 ? 'color:#DBDBDB' : ''"
        @click="handle(item.index)"
      >
        <el-tooltip
          placement="top"
          effect="light"
          v-if="index == 2 || index == 3"
          content="建设中"
          ><div>{{ item.text }}</div></el-tooltip
        >
        <div v-else>
          {{ item.text }}
        </div>
      </div>
    </div>
    <div
      v-if="indexA == 0"
      style="display: flex; flex-wrap: wrap; justify-content: space-between"
    >
      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>旅游品牌</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in brand2"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
             <span class="span" v-if="item.btn" @click="btn(item.text,item.id)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn }}</span
            >
            <span class="span" v-if="item.btn2" @click="btn2(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn2 }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
          </div>

        </div>
        <div style="display: flex; justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="3"
            :total="5"
            small
            @current-change="change"
          >
          </el-pagination>
        </div>
      </div>

      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>旅游产业</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in cultural2"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn" @click="btn(item.text,item.id)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn }}</span
            >
             <span class="span" v-if="item.btn2" @click="btn2(item.text,item.total,item.text,index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn2 }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size:12px;margin-right:3px">{{item.txt}}</span>
            <span style="font-size:12px;color:#f96c70" v-if="item.trend.type == 0">{{item.trend.text}}</span>
            <span style="font-size:12px;color:#30c566" v-if="item.trend.type == 1">{{item.trend.text}}</span>
          </div>

        </div>

        <div style="display: flex; justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="3"
            :total="6"
            small
            @current-change="change2"
          >
          </el-pagination>
        </div>
      </div>



       <el-dialog
        :title="title"
        :visible.sync="dialogMedal"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div style="color:#000;font-size:14px">{{oText}}</div>
        <div style="color:#1492ff;font-size:12px"><span >{{totalTitle}}</span></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart" style="width: 640px; height: 303px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible2"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="charts" style="width: 640px; height: 303px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible3"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chartss" style="width: 640px; height: 303px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible4"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="ball" style="width: 640px; height: 303px"></div>
      </el-dialog>

       <el-dialog
        :title="title"
        :visible.sync="dialogVisible5"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div class="dialog_wrap">
          <div class="dialog_box" style="font-size:16px;font-weight:bold;color:#000;">
            <div class="dialog_left">名称</div>
            <div class="dialog_right">地址</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">台州花木城景区</div>
            <div class="dialog_right">浙江省台州市路桥区台州花木城1号</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">路桥区中央山公园（一期、二期）</div>
            <div class="dialog_right">路桥区中央山麓</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">路桥区金大田景区</div>
            <div class="dialog_right">路桥区新桥镇金良社区</div>
          </div>
  
        </div>
      </el-dialog>

       <el-dialog
        :title="title"
        :visible.sync="dialogVisible6"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div class="dialog_wrap">
          <div class="dialog_box" style="font-size:16px;font-weight:bold;color:#000;">
            <div class="dialog_left">名称</div>
            <div class="dialog_right">地址</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">古街社区</div>
            <div class="dialog_right">路桥街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">石浜村</div>
            <div class="dialog_right">路桥街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">张李村</div>
            <div class="dialog_right">路南街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">水滨村</div>
            <div class="dialog_right">螺洋街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">上山童村</div>
            <div class="dialog_right">桐屿街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">春泽社区</div>
            <div class="dialog_right">桐屿街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">小稠村</div>
            <div class="dialog_right">桐屿街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">丁前村</div>
            <div class="dialog_right">桐屿街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">蒋僧桥村</div>
            <div class="dialog_right">峰江街道</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">扶雅社区</div>
            <div class="dialog_right">新桥镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">新兴村</div>
            <div class="dialog_right">新桥镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">华章村</div>
            <div class="dialog_right">新桥镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">金良社区</div>
            <div class="dialog_right">新桥镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">云湖村</div>
            <div class="dialog_right">横街镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">天赐湖村</div>
            <div class="dialog_right">横街镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">上林村</div>
            <div class="dialog_right">横街镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">镇东村</div>
            <div class="dialog_right">蓬街镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">小伍份村</div>
            <div class="dialog_right">蓬街镇</div>
          </div>
  
        </div>
      </el-dialog>

       <el-dialog
        :title="title"
        :visible.sync="dialogVisible7"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div class="dialog_wrap">
          <div class="dialog_box" style="font-size:16px;font-weight:bold;color:#000;">
            <div class="dialog_left">名称</div>
            <div class="dialog_right">地址</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">台州市路桥无问西东民宿</div>
            <div class="dialog_right">路桥街道十里长街北173-179号</div>
          </div>
  
        </div>
      </el-dialog>

       <el-dialog
        :title="title"
        :visible.sync="dialogVisible8"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div class="dialog_wrap">
          <div class="dialog_box" style="font-size:16px;font-weight:bold;color:#000;">
            <div class="dialog_left">名称</div>
            <div class="dialog_right">地址</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">中共黄岩首届县委旧址</div>
            <div class="dialog_right">峰江街道蒋僧桥村戴大夫故居</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">林泗斋纪念馆</div>
            <div class="dialog_right">新桥镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">陈安宝纪念馆</div>
            <div class="dialog_right">横街镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">筑塘博物馆</div>
            <div class="dialog_right">蓬街镇</div>
          </div>
          <div class="dialog_box">
            <div class="dialog_left">解放一江山岛军事遗址</div>
            <div class="dialog_right">金清镇龙泰胜景</div>
          </div>
  
        </div>
      </el-dialog>


    </div>

    <div v-if="indexA == 1" class="culture" style="width: 100%; height: 590px">
      <div class="title">
        <div class="titleDiv">
          <span></span>
          <div>游有所乐地图</div>
        </div>
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
          </div>
          <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
        </el-tooltip>
      </div>
      <div>
        <div id="map" style="width: 100%; height: 1000px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-liquidfill";
import { LabelsData } from "./map";
export default {
  components: {},
  data() {
    return {
      LabelsData,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      btnItem: [
        { text: "核心业务", index: 0 },
        { text: "资源分布", index: 1 },
        { text: "预警监测", index: 2 },
        { text: "制度创新", index: 3 },
      ],
      indexA: 0,
       brand: [
         {id:11, text: "3A级景区",num: "2",company: "个",txt:"当年累计2个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数2个" },
         {id:12, text: "省3A景区村庄",num: "13",company: "个",txt:"当年累计13个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数13个" },
         { text: "省级旅游度假区",num: "2",company: "个",txt:"当年累计2个  同比",trend:{type:1,text:"↑ 0%"}, btn2: "看详情" ,total:"总个数2个" },
         {id:14, text: "省等级民宿宿",num: "1",company: "个",txt:"当年累计1个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数1个" },
         {id:15, text: "红色景点",num: "5",company: "个",txt:"当年累计5个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数5个" },
       ],
       brand2: [
        {id:11, text: "3A级景区",num: "2",company: "个",txt:"当年累计2个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数2个" },
         {id:12, text: "省3A景区村庄",num: "13",company: "个",txt:"当年累计13个  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" ,total:"总个数13个" },
         { text: "省级旅游度假区",num: "2",company: "个",txt:"当年累计2个  同比",trend:{type:1,text:"↑ 0%"}, btn2: "看详情" ,total:"总个数2个" },
       ],
       cultural: [
        {id:1, text: "旅游接待总人数",num: "150.6万",company: "人次",txt:"当年累计150.6万人次 同比",trend:{type:1,text:"↑ 10%"}, btn: "看趋势"},
        {id:2, text: "旅游总收入",num: "17.86",company: "亿元",txt:"当年累计17.86亿元 同比",trend:{type:1,text:"↑ 12%"}, btn: "看趋势"},
        {id:3, text: "旅行社",num: "14",company: "家",txt:"当年累计14家  同比",trend:{type:1,text:"↑ 0%"}, btn2: "看详情" ,total:"总个数14家" },
        {id:4, text: "星级饭店",num: "4",company: "家",txt:"当年累计4家  同比",trend:{type:1,text:"↑ 0%"}, btn2: "看详情" ,total:"总个数4家" },
         {id:5, text: "旅游投诉量",num: "6",company: "次",txt:"当年累计6次  同比",trend:{type:0,text:"↓ 40%"}, btn: "看趋势"},
        {id:6, text: "旅游投诉量结案率",num: "6",company: "次",txt:"当年累计6次 同比",trend:{type:0,text:"↓ 40%"}, btn: "看趋势"},
      ],
      cultural2: [
        {id:1, text: "旅游接待总人数",num: "150.6万",company: "人次",txt:"当年累计150.6万人次 同比",trend:{type:1,text:"↑ 10%"}, btn: "看趋势"},
        {id:2, text: "旅游总收入",num: "17.86",company: "亿元",txt:"当年累计17.86亿元 同比",trend:{type:1,text:"↑ 12%"}, btn: "看趋势"},
        {id:3, text: "旅行社",num: "14",company: "家",txt:"当年累计14家  同比",trend:{type:1,text:"↑ 0%"}, btn2: "看详情" ,total:"总个数14家" },
      ],
      show: true,
      nowTime: null,
      time1: "",
      time2: "",
      dialogMedal: false,
      totalTitle:"",
      title:"",
      oText:""
    };
  },
  created() {
    const isZzd = JSON.stringify(navigator.userAgent).indexOf("TaurusApp") > -1;
    if (isZzd) {
      this.listenResize();
    } else {
      window.location.href =
        "https://dgov-integrate.jinhua.gov.cn:5443/szjh-gov-pc/#/404";
    }
  },
  mounted() {
    // this.init();
    // this.inits();
    // this.initss();
    // this.initsss();
  },
  watch: {
    dialogVisible(e) {
      if (e) {
        setTimeout(() => {
          this.init();
        }, 1);
      }
    },
    dialogVisible2(e) {
      if (e) {
        setTimeout(() => {
          this.inits();
        }, 1);
      }
    },
    dialogVisible3(e) {
      if (e) {
        setTimeout(() => {
          this.initss();
        }, 1);
      }
    },
    dialogVisible4(e) {
      if (e) {
        setTimeout(() => {
          this.initsss();
        }, 1);
      }
    },
  },
  methods: {
    listenResize() {
      setTimeout(() => {
        window.onresize = () => {
          this.init();
          this.inits();
          this.initss();
          this.initsss();
        };
      }, 0);
    },
    mouseenter() {
      //获取当前时间并打印
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      // this.time1 = "数据范围：" + yy + "年" + "1月-" + mm + "月";
      this.time1 = "数据范围：截止2022年1月";
      this.time2 = "更新时间：" + yy + "年" + mm + "月" + dd + "日";
    },
    nowSize(val, initWidth = 1554) {
      let nowClientWidth = document.documentElement.clientWidth;
      return val * (nowClientWidth / initWidth);
    },
    init() {
      this.chart = echarts.init(this.$refs.chart);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["一月", "二月", "三月", "四月", "五月", "六月"],
          axisLabel: {
            textStyle: {
              fontSize: this.nowSize(18),
            },
          },
        },
        yAxis: {
          type: "value",
          splitNumber: 3,
          axisLabel: {
            textStyle: {
              fontSize: this.nowSize(18),
            },
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{c}万人次",
        },
        title: {
          left: "left",
          text: "近六个月旅游接待人数",
          textStyle: {
            fontSize: this.nowSize(18),
          },
        },
        series: [
          {
            data: [23.48, 14.42, 32.32, 27.49, 24.69, 28.2],
            textStyle: {
              fontSize: this.nowSize(18),
            },
            itemStyle: {
              color: "#39A2FF",
            },
            type: "line",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#ECF6FF", // 0% 处的颜色
                },
                {
                  offset: 0.4,
                  color: "#ABD4F8", // 100% 处的颜色
                },
                {
                  offset: 0,
                  color: "#69B9FF", // 100% 处的颜色
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    inits() {
      this.chart = echarts.init(this.$refs.charts);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["一月", "二月", "三月", "四月", "五月", "六月"],
          axisLabel: {
            textStyle: {
              fontSize: this.nowSize(18),
            },
          },
        },
        yAxis: {
          type: "value",
          splitNumber: 3,
          axisLabel: {
            textStyle: {
              fontSize: this.nowSize(18),
            },
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{c}亿元",
        },
        title: {
          left: "left",
          text: "近六个月旅游收入",
          textStyle: {
            fontSize: this.nowSize(18),
          },
        },
        series: [
          {
            data: [2.7, 1.63, 3.84, 3.36, 2.96, 3.37],
            textStyle: {
              fontSize: this.nowSize(16),
            },
            itemStyle: {
              color: "#39A2FF",
            },
            type: "line",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#ECF6FF", // 0% 处的颜色
                },
                {
                  offset: 0.4,
                  color: "#ABD4F8", // 100% 处的颜色
                },
                {
                  offset: 0,
                  color: "#69B9FF", // 100% 处的颜色
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    initss() {
      this.chart = echarts.init(this.$refs.chartss);
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["一月", "二月", "三月", "四月", "五月", "六月", "七月"],
          axisLabel: {
            textStyle: {
              fontSize: this.nowSize(18),
            },
          },
        },
        yAxis: {
          type: "value",
          splitNumber: 3,
          axisLabel: {
            textStyle: {
              fontSize: this.nowSize(18),
            },
          },
        },
        tooltip: {
          trigger: "axis",
        },
        title: {
          left: "left",
          text: "近六个月旅游投诉量",
          textStyle: {
            fontSize: this.nowSize(18),
          },
        },
        series: [
          {
            data: [0, 0, 1, 4, 0, 0, 1],
            textStyle: {
              fontSize: this.nowSize(16),
            },
            itemStyle: {
              color: "#39A2FF",
            },
            type: "line",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#ECF6FF", // 0% 处的颜色
                },
                {
                  offset: 0.4,
                  color: "#ABD4F8", // 100% 处的颜色
                },
                {
                  offset: 0,
                  color: "#69B9FF", // 100% 处的颜色
                },
              ]),
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    initsss() {
      this.chart = echarts.init(this.$refs.ball);
      let option = {
        title: {
          text: "近六个月旅游投诉结案率",
          textStyle: {
            fontSize: this.nowSize(18),
          },
        },
        series: [
          {
            type: "liquidFill",
            data: [1],
            radius: "80%",
            center: ["75%", "50%"],
            outline: {
              show: true,
              itemStyle: {
                shadowColor: "#fff", // 边框的阴影颜色,
                borderColor: "#1D91FF", // 边框颜色
                borderWidth: 5, // 边框的宽度
              },
            },
            itemStyle: {
              color: "#1D91FF", // 水球显示的背景颜色
            },
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    handle(e) {
      if (e == 1) {
        this.show = false;
        setTimeout(() => {
          this.initMap();
        }, 1);
      } else if (e == 0) {
        this.show = true;
      } else {
        console.log(e);
      }
      if (e == 0 || e == 1) {
        this.indexA = e;
      }
    },
    initMap() {
      var map = new AMap.Map("map", {
        viewMode: "3D",
        zoom: 13,
        center: [121.400754, 28.526864],
        resizeEnable: true,
      });
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        // 开启标注避让，默认为开启，v1.4.15 新增属性
        collision: false,
        // 开启标注淡入动画，默认为开启，v1.4.15 新增属性
        animation: false,
      });

      map.add(layer);

      var markers = [];

      for (var i = 0; i < LabelsData.length; i++) {
        var curData = LabelsData[i];
        curData.extData = {
          index: i,
        };

        var labelMarker = new AMap.LabelMarker(curData);

        markers.push(labelMarker);

        layer.add(labelMarker);
      }

      map.setFitView();
    },
    change(e) {
      if (e == 1) {
        this.brand2 = this.brand.slice(0, 3);
      } else if (e == 2) {
        this.brand2 = this.brand.slice(3, 6);
      } 
    },
    change2(e) {
      if (e == 1) {
        this.cultural2 = this.cultural.slice(0, 3);
      } else if (e == 2) {
        this.cultural2 = this.cultural.slice(3, 6);
      } 
    },
    btn(e, i) {
      console.log(e);
      console.log(i);
      this.title = e;
      if(i == 1){
        this.dialogVisible = true;
      } else if(i == 2){
        this.dialogVisible2 = true;
      } else if(i == 5){      
        this.dialogVisible3 = true;
      } else if(i == 6){
        this.dialogVisible4 = true;
      } else if(i == 11){
        this.dialogVisible5 = true;
      } else if(i == 12){
        this.dialogVisible6 = true;
      } else if(i == 14){
        this.dialogVisible7 = true;
      } else if(i == 15){
        this.dialogVisible8 = true;
      }
    },
    btn2(e,v,t,i) {
      this.dialogMedal = true;
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
    },
  },
};
</script>

<style lang="less">
.body {
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 4px 12px 2px rgba(42, 82, 117, 0.06);
  border-radius: 4px;
  padding: 20px;
  .head {
    margin: 0 auto;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(
      270deg,
      rgba(20, 146, 255, 0.81) 1%,
      #0085f8 100%
    );
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    .left {
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #ffffff;
      line-height: 32px;
      font-weight: 500;
      font-size: 24px;
    }
    .right {
      font-size: 20px;
      opacity: 0.9;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #ffffff;
      text-align: right;
      line-height: 28px;
      font-weight: 400;
    }
  }
  .btn:hover {
    cursor: pointer;
  }
  .btn {
    width: 100%;
    height: 40px;
    background: #f7f7f7;
    border-radius: 6px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    div {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-family: PingFangSC-Medium;
    }
    .active {
      background: #ffffff;
      border: 1px solid #1492ff;
      border-radius: 6px;
      color: #1492ff;
      font-weight: 600;
    }
  }
  .culture {
    width: 49%;
    height: 474px;
    border: 1px solid #9dd2ff;
    border-radius: 4px;
    margin-bottom: 16px;
    overflow: hidden;
    .title {
      width: 100%;
      height: 40px;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 18.6px;
      .titleDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          width: 4px;
          height: 20px;
          background: #1492ff;
          border-radius: 2px;
          margin-left: 12px;
        }
        div {
          font-size: 20px;
          margin-left: 8px;
          font-family: Helvetica;
          font-size: 20px;
          color: #000000;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      img {
        width: 19.948px;
      }
    }
    .common {
      width: 100%;
      padding: 21.948px;
      display: flex;
      .left {
        height: 199.485px;
        background-color: #f7f7f7;
        margin-left: 19.948px;
        padding: 0 19.948px;
        .up {
          width: 100%;
          height: 49.879px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1.008px solid #ccc;
          img {
            width: 19.948px;
            height: 19.948px;
          }
          div {
            font-size: 19.964px;
            margin-left: 4.991px;
          }
        }
        .down {
          width: 100%;
          height: 139.639px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .index {
            display: flex;
            flex-direction: column;
            align-items: center;
            .num {
              margin-bottom: 19.948px;
              color: #6ab2fb;
              font-size: 29.931px;
            }
            .item {
              font-size: 17.965px;
            }
          }
        }
      }
    }
    .cultureItem {
      width: calc(100% - 32px);
      height: 118px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.05);
      border-radius: 8px;
      margin: 11px 16px 11px;
      padding: 16px;
      span:nth-child(1) {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 500;
      }
      .span {
        width: 78px;
        height: 26px;
        float: right;
        background: #ffffff;
        border: 0.5px solid #1492ff;
        border-radius: 4px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #1492ff;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 26px;
        .iconfont {
          color: #1492ff;
        }
      }
      .span:hover {
        cursor: pointer;
      }
      div:nth-child(2) {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #1492ff;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
        display: flex;
        align-items: center;
        .company {
          margin-left: 4px;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #666666;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    .el-pagination {
      button {
        display: none;
      }
      .number {
        color: #59aaf5;
        background: #edf4ff;
      }
      .active {
        color: #ffffff;
        background: #1492ff;
      }
    }
  }
  .art {
    margin-top: 9.982px;
    border-radius: 7.982px;
    overflow: hidden;
    border: 0.992px solid #1191ff;
    .title {
      width: 100%;
      height: 47.895px;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 29.931px 0 9.982px;
      div {
        font-size: 15.965px;
        font-weight: 900;
        padding-left: 19.948px;
        border-left: 4.991px solid #1191ff;
      }
      img {
        width: 19.948px;
      }
    }
    .commonn {
      width: 100%;
      padding: 21.948px;
      display: flex;
      .data {
        width: 488.746px;
        height: 219.434px;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .dataItem {
          display: flex;
          justify-content: space-between;
          padding: 0 42.889px 0 19.948px;
          .left {
            display: flex;
            justify-content: center;
            align-items: center;
            .spot {
              width: 9.982px;
              height: 9.982px;
              border-radius: 50%;
              background-color: #058cfe;
              margin-right: 14.957px;
            }
            .item {
              font-size: 15.965px;
            }
          }
          .num {
            color: #058cfe;
            font-size: 15.965px;
          }
        }
      }
    }
  }
}
.el-dialog__wrapper {
  width: 100%;
  .el-dialog__header {
    .el-dialog__title {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      line-height: 26px;
      font-weight: 500;
      border-left: 4px solid #1492ff !important;
      border-radius: 2px;
      padding-left: 8px;
    }
    .el-dialog__headerbtn {
      width: 26px;
      height: 26px;
      // background: #1492ff;
      border-radius: 50%;
      i {
        color: #000;
      }
    }
  }
}
.el-dialog__wrapper::-webkit-scrollbar {
  width: 0px;
}
.v-modal {
  width: 100% !important;
}
.el-dialog__body {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.dialog_wrap{
  width: 100%;
}
.dialog_box{
   display: flex;
  justify-content: space-between;
  align-items: center;
  color:#000;
  margin-top: 15px;
}
.dialog_left{
  flex:1;
  // text-align: center;
}
.dialog_right{
  flex:1;
  // text-align: center;
}
</style>